import React, { Component } from "react";
import { connect } from "react-redux";

export default function LayoutFooter(props) {
  const d = new Date();

  function prevent(e) {
    e.preventDefault();
  }

  return (
    <nav className="layout-footer footer bg-footer-theme">
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="pt-3">
          <span className="footer-text">© {d.getFullYear()} ProStruct360</span>
          {" | "}
          <a href="https://prostruct360.com/terms/" target="_blank">
            Terms of Service
          </a>
          {" | "}
          <a href="https://prostruct360.com/privacy/" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
    </nav>
  );
}
