const initialstate = {
    jobId:0,
    address:0
};

export default (state = initialstate,action) => {
    switch (action.type) {
        case "JOB_ID":
            return {
                ...state,
                jobId: action.payload
            }
            break;
        case "ADDRESS_ID":
            return {
                ...state,
                addressId: action.payload
            }
            break;
    
        default:
            return state;
            break;
    }
}