import React from "react";
import styles from "./NotFound.module.scss";

function NotFound(props) {
  return (
    <div className={styles.Container}>
      <div className="text-center">
        <h1 className={styles.Status}>404</h1>
        <p>Uh oh. The page you requested could not be found.</p>
      </div>
    </div>
  );
}

export default NotFound;
