import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import Select from "react-select";
import { getCurrentPartnerId } from "../../../utils/auth/partnerhelpers";
import { Button, Col, Form, Row } from "react-bootstrap";

export default function UserSelector(props) {
  const [isClickToEditMode, setIsClickToEditMode] = React.useState(
    props.isClickToEditMode
  );
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      role_names: props.roleNames,
      partner_filter: props.showAllPartnerUsers
        ? {
            _or: [
              { user_partners: { partner_id: { _eq: getCurrentPartnerId() } } },
              {
                _and: [
                  {
                    primary_partner_id: { _neq: getCurrentPartnerId() },
                  },
                  {
                    user_roles: { role: { name: { _eq: "Owner/Operator" } } },
                  },
                ],
              },
            ],
          }
        : { user_partners: { partner_id: { _eq: getCurrentPartnerId() } } },
    },
  });

  const { loading: user_loading, data: user_data } = useQuery(
    QUERY_SELECTED_USER,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: props.value ?? 0,
      },
    }
  );

  useEffect(() => {
    if (props.value !== null) {
    }
  }, [props.value]);

  if (loading) return null;

  function handleChange(e) {
    if (e != null && !Array.isArray(e)) {
      e = [e];
    }

    if (props.isMulti) {
      props.onChange(
        Array.isArray(e)
          ? e.map(function (v, index) {
              return v.value;
            })
          : []
      );
    } else {
      props.onChange(e ? e[0].value : null);
    }
  }

  function handleBlur() {
    props.onBlur(props.name, true);
  }

  const options = data.user.map(function (e, index) {
    return {
      value: e.id,
      label:
        e.full_name +
        (props.showAllPartnerUsers &&
        e.primary_partner?.id != getCurrentPartnerId()
          ? ` (Vendor: ${e.primary_partner?.name})`
          : ""),
      key: index,
    };
  });

  const selected = props.isMulti
    ? _.filter(options, function (o) {
        return _.includes(props.value, o.value);
      })
    : options.filter((option) => option.value == props.value);

  if (isClickToEditMode) {
    return (
      <>
        <Row>
          <Col>
            <Form.Control
              type="text"
              name="user_name"
              value={user_data?.user[0]?.full_name ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={true}
            />
          </Col>
          <Col>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              onClick={() => setIsClickToEditMode(false)}
            >
              Click To Edit
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Select
      key={`my_unique_select_key__${props.value}`}
      id={props.name}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      defaultValue={selected}
      isMulti={props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      isClearable={props.isClearable}
      placeholder={props.placeholder ? props.placeholder : "None"}
      isDisabled={props.isDisabled}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
    />
  );
}

export const QUERY = gql`
  query getUsersInRole($role_names: [String!], $partner_filter: user_bool_exp) {
    user(
      order_by: { full_name: asc }
      where: {
        _and: [
          $partner_filter
          { user_roles: { role: { name: { _in: $role_names } } } }
          { is_active: { _eq: true } }
        ]
      }
    ) {
      id
      full_name
      primary_partner {
        id
        name
      }
    }
  }
`;

const QUERY_SELECTED_USER = gql`
  query getUserSingle($id: Int!) {
    user(order_by: { full_name: asc }, where: { id: { _eq: $id } }) {
      id
      full_name
      primary_partner {
        id
        name
      }
    }
  }
`;
