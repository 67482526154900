import React from "react";

export default class DevErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // Optional: we could report the error to a service here or log it to the console
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      console.error("customerror", this.state.error);
      return (
        <>
          <h1>Something went wrong.</h1>
          <p>{JSON.stringify(this.state.error)}</p>
        </>
      );
    }

    return this.props.children;
  }
}
