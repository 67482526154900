import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { subscriptions, features } from "../../utils/subscriptionsPresets";

export default function PayFieldsComponent(props) {
  useEffect(() => {
    let externalScript = document.createElement("script");
    let jQueryScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      process.env.REACT_APP_PAYRIX_URL + "/payFieldsScript"
    );
    jQueryScript.setAttribute(
      "src",
      "https://code.jquery.com/jquery-3.6.0.min.js"
    );
    externalScript.onload = () => {
      window.PayFields.config.apiKey = process.env.REACT_APP_PAYRIX_KEY;
      window.PayFields.config.merchant = process.env.REACT_APP_PAYRIX_MERCH;
      window.PayFields.config.mode = "token";

      window.PayFields.fields = [
        {
          type: "number",
          element: "#card_number",
        },
        {
          type: "cvv",
          element: "#cvv1",
        },
        {
          type: "name",
          element: "#name",
          placeholder: "Alex Jones",
        },
        {
          type: "expiration",
          element: "#expiration",
        },
      ];

      if (props.saas_payrix_customer_id) {
        window.PayFields.fields.push({
          type: "customer_id",
          element: "#customer_id",
          values: { customer_id: props.saas_payrix_customer_id },
        });
      }

      window.PayFields.onSuccess = (response) => {
        props.handleSuccess(response);
      };
      window.PayFields.onFailure = (response) => {
        props.handleFailure(response);
      };
      window.PayFields.onValidationFailure = (response) => {
        props.handleValidationFailure(response);
      };
      window.PayFields.customizations = {
        style: {
          ".input": {
            width: "100%",
            lineHeight: "1.5",
            height: "30px",
            boxSizing: "border-box",
            background: "white",
            backgroundClip: "padding-box",
            border: "1px solid #e2dee5",
            borderRadius: "0.25rem",
            padding: "10px",
          },
          ".form-error": {
            marginTop: "2px",
            fontSize: "12px",
            color: "#DB0E00",
          },
        },
        placeholders: {
          "#name": "",
          "#expiration": "MM / YY",
        },
      };

      // if (window.PayFields.appended) {
      //   window.PayFields.addFields();
      // }

      //console.log(window.PayFields);
    };

    document.body.appendChild(jQueryScript);
    document.body.appendChild(externalScript);
    return () => {
      document.body.removeChild(externalScript);
      document.body.removeChild(jQueryScript);
      window.PayFields = undefined;
      try {
        delete window.PayFields;
      } catch (e) {}
      console.log(window.PayFields);
      var payFieldIFrames = document.getElementsByName("payFields_main");
      for (var i = 0; i < payFieldIFrames.length; i++) {
        payFieldIFrames[i].parentNode.removeChild(payFieldIFrames[i]);
      }
    };
  }, []);

  useEffect(() => {
    if (window.PayFields) {
      window.PayFields.config.billingAddress = props.billingAddress;
    }
  }, [props.billingAddress]);
  return (
    <>
      <Form.Group as={Row} style={{ display: "none" }}>
        <Form.Label column sm={3} className="text-sm-right">
          Customer
        </Form.Label>
        <Col sm={6}>
          <div
            id="customer_id"
            className="input-field"
            style={{ height: "30px" }}
          ></div>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={3} className="text-sm-right">
          Card Number <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={6}>
          <div
            id="card_number"
            className="input-field"
            style={{ height: "30px" }}
          ></div>

          <img
            src="/img/Card-Brand-Logos.png"
            className="mt-2"
            style={{ width: "250px" }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={3} className="text-sm-right">
          Cardholder Name <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={6}>
          <div
            id="name"
            className="input-field"
            style={{ height: "30px" }}
          ></div>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={3} className="text-sm-right">
          Card Expiration (MM/YY) <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={6}>
          <div
            id="expiration"
            className="input-field"
            style={{ height: "30px" }}
          ></div>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={3} className="text-sm-right">
          CVV Code <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={6}>
          <div
            id="cvv1"
            className="input-field"
            style={{ height: "30px" }}
          ></div>
        </Col>
      </Form.Group>
    </>
  );
}
