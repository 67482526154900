import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { subscriptions, features } from "../../utils/subscriptionsPresets";
import { getCurrentPartnerId } from "../../utils/auth/partnerhelpers";
import { useImperativeQuery } from "../../utils/gqlutils";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export default function SelectSubscription(props) {
  const callQuery = useImperativeQuery(QUERY);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const { loading, data, error, refetch } = useQuery(QUERY, {
  //   enabled: false,
  //   fetchPolicy: "network-only",
  //   variables: {
  //     partner_id: getCurrentPartnerId(),
  //   },
  // });
  const loadConfig = () => {
    callQuery({ partner_id: getCurrentPartnerId() }).then((response) => {
      setLoading(false);
      setData(response.data);
    });
  };
  const loadConfigOnclick = (s) => {
    callQuery({ partner_id: getCurrentPartnerId() }).then((response) => {
      setLoading(false);
      setData(response.data);
      props.setFieldValue("subscription", s.name);
      props.setFieldValue("subscription_id", s.id);
      if (
        (props.current_suscription == "Complete" ||
          props.current_suscription == "Unlimited") &&
        (s.name == "Quotes" || s.name == "Lite")
      ) {
        if (response?.data?.partner_email_config?.length > 0)
          props.setFieldValue("downgrade_flag", true);
        else props.setFieldValue("downgrade_flag", false);
      } else {
        props.setFieldValue("downgrade_flag", false);
      }
    });
  };
  useEffect(() => {
    if (props.is_upgrade) loadConfig();
  }, []);

  if (loading && props.is_upgrade) return <span>loading...</span>;
  return (
    <>
      <Form.Group as={Row}>
        <Col sm={12}>
          {props.isUpgradePage && (
            <h2 className="text-center">
              Your current subscription is{" "}
              <b>
                {
                  subscriptions.filter(
                    (f) => f.id == props.currentSubscriptionId
                  )[0].name
                }
              </b>
            </h2>
          )}
          <h3 className="text-center">
            Select the version below that best fits your needs
          </h3>
          {!props.isUpgradePage && (
            <h6 className="text-center">
              Quotes is always free, but try one of our premium versions free
              for the next 2 weeks.
            </h6>
          )}
          <div className="card-deck mt-5">
            {subscriptions.map((s) => {
              let trialText = s.trialText;
              if (props.is_upgrade && s.name == "Quotes") trialText = "";
              return (
                <div
                  key={`${s.name}-card`}
                  id={`${s.name}-card`}
                  className={`card mb-4 cursor-pointer ${
                    props.values.subscription === s.name
                      ? "text-white bg-primary"
                      : "bg-lighter"
                  }`}
                  onClick={(e) => {
                    if (props.is_upgrade) loadConfigOnclick(s);
                    else {
                      props.setFieldValue("subscription", s.name);
                      props.setFieldValue("subscription_id", s.id);
                    }
                  }}
                >
                  <div className="card-body" role="button">
                    <h3 className="card-title text-center mb-4">{s.name}</h3>
                    <h5 className="text-center">{s.price}</h5>
                    <h5 className="text-center">
                      {!props.currentSubscriptionId && trialText}
                      <br />
                    </h5>
                    <h3 className="text-center mb-0">
                      <i
                        className={`fa ${
                          props.values.subscription == s.name
                            ? "fa-check"
                            : "fa-none"
                        }`}
                      ></i>
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col>
          <ul className="fa-ul text-large">
            <li key="users">
              <span className="fa-li text-primary">
                <i className="fa fa-check-square"></i>
              </span>
              {(props.values.subscription == "Quotes" ||
                props.values.subscription == "Lite") &&
                "1 user"}
              {props.values.subscription == "Complete" && "2 users"}
              {props.values.subscription == "Unlimited" && "Unlimited users"}
            </li>
            {features.map((f, index) => {
              let maximumFeature =
                subscriptions.find((f) => f.name == props.values.subscription)
                  ?.maximumFeatureRank ?? 0;

              if (maximumFeature < f.rank)
                return (
                  <>
                    <li key={index}>
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      <s>{f.item}</s>
                    </li>
                  </>
                );
              return (
                <>
                  <li key={index}>
                    <span className="fa-li text-primary">
                      <i className="fa fa-check-square"></i>
                    </span>
                    {f.item}
                  </li>
                  {f.rank == maximumFeature && (
                    <li
                      key="not-included"
                      style={{ marginTop: "20px", marginBottom: "10px" }}
                    >
                      <b>
                        All the great features above are included on the{" "}
                        {props.values.subscription} level
                      </b>
                    </li>
                  )}
                </>
              );
            })}
          </ul>
          <h4 className="text-primary ml-4 my-3">
            <a
              href="https://prostruct360.com/features-table-page/"
              target="_blank"
            >
              Click here for a full feature list comparison
            </a>
          </h4>
        </Col>
      </Form.Group>
    </>
  );
}

const QUERY = gql`
  query getUserMailConfig($partner_id: Int!) {
    partner_email_config(
      where: { _and: [{ partner_id: { _eq: $partner_id } }] }
    ) {
      id
    }
  }
`;
