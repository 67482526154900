export function parseIntRemoveCommas(value) {
  if (typeof value === "number") {
    return value;
  }

  return parseInt(value.replace(/,/g, ""));
}

export function parseFloatRemoveCommas(value) {
  if (typeof value === "number") {
    return value;
  }

  if (value === null || value === undefined || value == "") {
    return 0;
  }

  return parseFloat(value.replace(/,/g, ""));
}

export function roundTo2(value, alternateLength) {
  let length = alternateLength ? alternateLength : 2;
  value = Math.round(value * 100) / 100;
  return parseFloat(value.toFixed(length));
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatMoney(value) {
  return formatter.format(value).replace(/\D00$/, "");
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

export function encodeUrl(url) {
  var encodedArray = url?.split("://");
  if (!encodedArray || !encodedArray[1]) return "";
  let encoded = encodedArray[0] + "://" + escape(encodedArray[1]);
  return encoded;
}
