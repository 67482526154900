import Sidenav from './SidenavComponent'
import SidenavBlock from './SidenavBlock'
import SidenavDivider from './SidenavDivider'
import SidenavHeader from './SidenavHeader'
import SidenavMenu from './SidenavMenu'
import SidenavLink from './SidenavLink'
import SidenavRouterLink from './SidenavRouterLink'

Sidenav.Block = SidenavBlock
Sidenav.Divider = SidenavDivider
Sidenav.Header = SidenavHeader
Sidenav.Menu = SidenavMenu
Sidenav.Link = SidenavLink
Sidenav.RouterLink = SidenavRouterLink

export default Sidenav
