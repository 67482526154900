export const actionTypes =  {
    messageCheck: 'EMAILS_CHECK',
    messageUncheck: 'EMAILS_UNCHECK',
    messageClearSelected: 'EMAILS_MESSAGES_CLEAR_SELECTED',
    messageRetrieve: 'EMAILS_MESSAGES_RETRIEVE',
    messageCount: 'EMAILS_MESSAGES_COUNT',
    pageSizeChange: 'EMAILS_MESSAGES_PAGE_SIZE_CHANGE',
    pageNumberChange: 'EMAILS_MESSAGES_PAGE_NUMBER_CHANGE',
    currentTab: 'CURRENT_TAB_SELECTED',
    sortBy: 'SORT_BY',
}