export const GET_BILLS = "GET_BILLS";
export const CHECK_BILL = "CHECK_BILL";
export const UN_CHECK_BILL = "UN_CHECK_BILL";
export const UN_CHECK_ALL_BILL = "UN_CHECK_ALL_BILL";
export const GET_PBILLS_ERROR = 'GET_PBILLS_ERROR';
export const CLEAR_SELECTED = 'CLEAR_SELECTED';


export const checkBill= bill => ({
    type: CHECK_BILL,
    payload: bill
});

export const unCheckBill= bill => ({
    type: UN_CHECK_BILL,
    payload: bill
});

export const unCheckAllBill= vendor => ({
    type: UN_CHECK_ALL_BILL,
    payload: vendor
});

export const clearSelected = () => ({
    type: CLEAR_SELECTED,
    payload: {}
});

export const GetBills = bills => ({
    type: GET_BILLS,
    payload: bills
});
