import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { getCurrentPartnerId } from "../../utils/auth/partnerhelpers";

export default function QuickbooksDefaultItemModal(props) {
  const { loading, data, error } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: { id: getCurrentPartnerId() },
  });

  const [updatePartner] = useMutation(UPDATE, {
    refetchQueries: ["getPartners", "GetPrimaryPartner"],
  });

  if (loading) return null;

  if (error) return <span>{JSON.stringify(error)}</span>;

  var partner = data.partner[0];
  var accounts = data.accounts;

  if (partner.qb_item_id != null) {
    return (
      <Modal
        show={props.modalOpen}
        size="lg"
        backdrop="static"
        onHide={() => props.setModalOpen(false)}
      >
        <Modal.Body className="bg-lighter">
          <div className="row">
            <div className="col-xl-12">
              <p>
                This partner already has a valid default quickbooks item set.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      show={props.modalOpen}
      size="lg"
      backdrop="static"
      onHide={() => props.setModalOpen(false)}
    >
      <Formik
        enableReinitialize
        initialValues={{ income_account_id: "", expense_account_id: "" }}
        validator={() => ({})}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          let result = await updatePartner({
            variables: {
              partner_id: getCurrentPartnerId(),
              income_account_id: values.income_account_id,
              expense_account_id: values.expense_account_id,
            },
          });
          if (result.data.createQBODefaultItem.success) {
            toast("Changes Saved!", {
              type: toast.TYPE.PRIMARY,
              autoClose: 2000,
            });
            setSubmitting(false);
            props.setModalOpen(false);
          } else {
            setSubmitting(false);
            toast("Error: " + result.data.createQBODefaultItem.message, {
              type: toast.TYPE.ERROR,
              autoClose: 2000,
            });
          }
        }}
        validationSchema={Yup.object().shape({
          income_account_id: Yup.string()
            .nullable()
            .strict(false)
            .trim()
            .required("Required"),
          expense_account_id: Yup.string()
            .nullable()
            .strict(false)
            .trim()
            .required("Required"),
        })}
        render={({
          form,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton style={{ display: "block" }}>
              <Modal.Title as="h5">
                Create Your Default Quickbooks Item
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col sm={12} className="p-2">
                  <Form.Group as={Row}>
                    <Col>
                      <p className="p-4">
                        Please choose a Quickbooks income account below to post
                        all income to and an Quickbooks expense account below to
                        post all labor to.
                      </p>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2} className="text-sm-right">
                      Income Account
                    </Form.Label>
                    <Col sm={9}>
                      <select
                        name="income_account_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.income_account_id}
                        className="custom-select"
                      >
                        <option value="">Please choose one...</option>
                        {accounts
                          .filter((f) => f.data_type === "Income")
                          .map((n) => {
                            return (
                              <option key={n.id} value={n.id}>
                                {n.name}
                              </option>
                            );
                          })}
                      </select>
                      {errors.income_account_id ? (
                        <div className="text-danger">
                          {errors.income_account_id}
                        </div>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2} className="text-sm-right">
                      Expense Account
                    </Form.Label>
                    <Col sm={9}>
                      <select
                        name="expense_account_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.expense_account_id}
                        className="custom-select"
                      >
                        <option value="">Please choose one...</option>
                        {accounts
                          .filter(
                            (f) =>
                              f.data_type === "Expense" ||
                              f.data_type === "CostofGoodsSold"
                          )
                          .map((n) => {
                            return (
                              <option key={n.id} value={n.id}>
                                {n.name}
                              </option>
                            );
                          })}
                      </select>
                      {errors.expense_account_id ? (
                        <div className="text-danger">
                          {errors.expense_account_id}
                        </div>
                      ) : null}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <div className="mr-auto float-left">
                <Button
                  variant="default"
                  onClick={() => props.setModalOpen(false)}
                >
                  Close
                </Button>
              </div>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                Create Default Quickbooks Item
              </Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
}

const QUERY = gql`
  query getPartner($id: Int!) {
    partner(where: { id: { _eq: $id } }) {
      id
      qb_item_id
    }
    accounts: getQBOAccounts(partner_id: $id, data_type: "accounts") {
      id
      name
      data_type
    }
  }
`;

const UPDATE = gql`
  mutation updatePartner(
    $partner_id: Int!
    $income_account_id: String!
    $expense_account_id: String!
  ) {
    createQBODefaultItem(
      partner_id: $partner_id
      income_account_id: $income_account_id
      expense_account_id: $expense_account_id
    ) {
      success
      message
    }
  }
`;
