export const subscriptions = [
  {
    id: 1,
    name: "Quotes",
    price: "Free",
    usd: "",
    numeric: 0,
    maximumFeatureRank: 3,
    trialText: "Waitlist Only",
  },
  {
    id: 2,
    name: "Lite",
    price: "$89/month",
    usd: "$89 USD",
    numeric: 89,
    maximumFeatureRank: 8,
    trialText: "Free for 2 weeks",
  },
  {
    id: 3,
    name: "Complete",
    price: "$199/month",
    usd: "$199 USD",
    numeric: 199,
    maximumFeatureRank: 13,
    trialText: "Free for 2 weeks",
  },
  {
    id: 4,
    name: "Unlimited",
    price: "$499/month",
    usd: "$499 USD",
    numeric: 499,
    maximumFeatureRank: 15,
    trialText: " ",
  },
];

export const features = [
  { rank: 1, item: "Estimates" },
  { rank: 2, item: "Invoicing" },
  { rank: 3, item: "Online Invoice Payments from your Customers" },
  { rank: 4, item: "Work Orders" },
  { rank: 6, item: "Photo and Document Storage" },
  {
    rank: 7,
    item: "Built-in Accounting to track money owed to you and money you owe",
  },
  {
    rank: 8,
    item: "Customized Branding on estimates, invoices, emails, and work orders",
  },
  { rank: 9, item: "Quickbooks Integration" },
  {
    rank: 10,
    item: "Email AI Client - connect your email (Gmail, Microsoft, etc) and our AI will auto-match the right emails to the right jobs",
  },
  { rank: 11, item: "Financial Reports" },
  { rank: 12, item: "Timelines/Gantt Charts" },

  { rank: 13, item: "Profit & Loss Super Report" },
  { rank: 14, item: "90% off ProStruct Alliance Annual Retreat" },
  { rank: 15, item: "1-on-1 Company Blueprinting Session" },
];
