import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import UserProvider from "./utils/auth/FirebaseUserProvider";
import DevErrorBoundary from "./DevErrorBoundary";

if (window.location.href.indexOf("localhost") == -1) {
  Bugsnag.start({
    apiKey: "968f2c10edbfc9735909c12de13ab76b",
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary =
  window.location.href.indexOf("localhost") == -1
    ? Bugsnag.getPlugin("react").createErrorBoundary(React)
    : DevErrorBoundary;

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};
const rootElement = document.getElementById("root");

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <UserProvider>
        <App />
      </UserProvider>
    </Provider>
  </ErrorBoundary>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
