import React from "react";
import { Row, Col, Form } from "react-bootstrap";

export default function StandardField(props) {
  return (
    <Form.Group as={Row} className={props.className}>
      <Form.Label
        column
        sm={props.labelColumn ? props.labelColumn : 3}
        className="text-sm-right"
      >
        {props.label}
      </Form.Label>
      <Col sm={props.fieldColumn ? props.fieldColumn : 9}>
        {props.helpText && (
          <Form.Text className="text-muted mt-2 mb-1">
            {props.helpText}
          </Form.Text>
        )}
        <Form.Control
          ref={props.ref ? props.ref : null}
          type={props.type ? props.type : "text"}
          as={props.type ? props.type : "input"}
          rows={props.rows}
          name={props.name}
          value={props.value}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          className={props.touched && props.errors ? "error" : null}
        />
        {props.touched && props.errors ? (
          <div className="text-danger">{props.errors}</div>
        ) : null}
      </Col>
    </Form.Group>
  );
}
