import React, { Component } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useQuery } from "@apollo/react-hooks";
import Loader from "../../shared/Loader";
import gql from "graphql-tag";
import {useUserData} from "../../utils/auth/useUserData.js";

export default function ModalQuickAdd(props) {
  let history = useHistory();
 
  const { loading, data, error } = useUserData(props.claims.user_id);
  return (
    <Modal className="modal-slide pt-0" show={props.show} onHide={props.close}>
      <Modal.Header closeButton className="p-3">
        <Modal.Title as="h5">Add New</Modal.Title>
       
      </Modal.Header>

      <Modal.Body className="m-0 pt-4">
        { loading ?
           <Loader />
           :
           <Row>          
            <Col xs={6}>
              <Button
                block
                variant="primary px-0 pt-4 pb-2 mb-4"
                onClick={() => {
                  history.push("/jobs/0");
                  props.close();
                }}
              >
                <span className="fas fa-wrench fa-2x"></span>
                <p className="pt-2">Job</p>
              </Button>
              <Button
                block
                variant="primary px-0 pt-4 pb-2 mb-4"
                onClick={() => {
                  history.push("/clients/0");
                  props.close();
                }}
              >
                <span className="fas fa-users fa-2x"></span>
                <p className="pt-2">Client</p>
              </Button>
              <Button
                block
                variant="primary px-0 pt-4 pb-2 mb-4"
                onClick={() => {
                  history.push("/vendors/0");
                  props.close();
                }}
              >
                <span className="ion ion-ios-list-box fa-2x"></span>
                <p className="pt-2">Vendor</p>
              </Button>
            </Col>
            <Col xs={6}>
              {
                (
                data?.user[0].user_roles[0].role_id==1 || 
                data?.user[0].user_roles[0].role_id==12 || 
                data?.user[0].user_roles[0].role_id==20 || 
                data?.user[0].allow_admin_all_user_permissions )
                &&
                <Button
                block
                variant="primary px-0 pt-4 pb-2 mb-4"
                onClick={() => {
                  history.push("/admin/users/0");
                  props.close();
                }}
              >
                <span className="fas fa-user fa-2x"></span>
                <p className="pt-2">Team User</p>
              </Button>

              }
             
              <Button
                block
                variant="primary px-0 pt-4 pb-2 mb-4"
                onClick={() => {
                  props.close();
                  props.showModal("Task");
                }}
              >
                <span className="ion ion-ios-list-box fa-2x"></span>
                <p className="pt-2">Task</p>
              </Button>
              <Button
                block
                variant="primary px-0 pt-4 pb-2 mb-4"
                onClick={() => {
                  window.location = "/partner/receivepayment";
                  props.close();
                }}
              >
                <span className="fa fa-money-check-alt fa-2x"></span>
                <p className="pt-2">Payment</p>
              </Button>
            </Col>
          </Row>
       } 
        
      </Modal.Body>
    </Modal>
  );
}

const GETUSER = gql`
  query GetUserData($id: Int!) {
    user(where:{id:{_eq:$id}})
    {
      id
      authorize_approve_vendor_payments
      allow_user_pay_vendor_early
      allow_admin_all_user_permissions
      user_roles{
        role_id
      }
    }
  }
`;