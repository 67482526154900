import React, { useState, useContext } from "react";
import Router from "./shared/Router";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { connect } from "react-redux";
import { UserContext } from "./utils/auth/FirebaseUserProvider";
import { updateThemeSettings } from "./store/actions/themeActions";
import themeSettings from "./vendor/libs/theme-settings";
import "./vendor/libs/react-toastify/react-toastify.scss";
import "./App.scss";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { ToastContainer } from "react-toastify";

function App(props) {
  // const consoleError = console.error;
  // const SUPPRESSED_ERRORS = ["Warning: "];

  // console.error = function filterErrors(msg, ...args) {
  //   if (!SUPPRESSED_ERRORS.some((entry) => msg.includes(entry))) {
  //     consoleError(msg, ...args);
  //   }
  // };

  const user = useContext(UserContext);
  themeSettings._themeSettings.settings.onSettingsChange = () =>
    props.updateThemeSettings();

  localStorage.removeItem("themeSettingsNavbarBg");
  localStorage.removeItem("themeSettingsSidenavBg");
  localStorage.removeItem("themeSettingsFooterBg");
  localStorage.removeItem("themeSettingsTheme");
  localStorage.removeItem("themeSettingsMaterial");

  const [claims, setClaims] = useState({
    initial: true,
    token: "",
    user_id: 0,
    default_role: "",
    allowed_roles: [],
  });

  const getAccessToken = async (forceRefresh) => {
    const result = await user.getIdTokenResult(forceRefresh);

    if (result.claims["https://hasura.io/jwt/claims"] == null) {
      //firebase.auth().signOut();
    }
    let user_id =
      result.claims["https://hasura.io/jwt/claims"]["x-hasura-user-id"];

    setClaims({
      initial: false,
      email: result.claims["email"],
      user_id: user_id,
      default_role:
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-default-role"],
      expires_on: result.expirationTime,
      subscription_id: 1,
      logo_url: `${process.env.PUBLIC_URL}/img/mark.png`,
      primary_partner_id: 0,
    });

    window._loq = window._loq || [];
    window._loq.push([
      "custom",
      {
        user_id: user_id,
        email: result.claims["email"],
        default_role:
          result.claims["https://hasura.io/jwt/claims"][
            "x-hasura-default-role"
          ],
      },
    ]);
    //window.StonlyWidget("identify", user_id);
  };

  if (user && user != "init" && claims.token == "") {
    getAccessToken(false);
  }

  if (!user && claims.initial == true) {
    setClaims({
      initial: false,
      email: "",
      user_id: null,
      default_role: "",
      expires_on: "",
    });
  }

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_URL.replace("http:", "ws:").replace(
      "https:",
      "wss:"
    ),
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        const result = await user.getIdTokenResult(false);
        return {
          headers: {
            Authorization:
              result && result.token ? `Bearer ${result.token}` : "",
          },
        };
      },
    },
  });

  const asyncAuthLink = setContext(async () => {
    if (user === null) {
      return {
        headers: {},
      };
    }
    const result = await user.getIdTokenResult(false);
    return {
      headers: {
        authorization: `Bearer ${result.token}`,
      },
    };
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    asyncAuthLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <ToastContainer autoClose={2000} newestOnTop={true} />
      <Router user={user} claims={claims} setClaims={setClaims} />
    </ApolloProvider>
  );
}

export default connect(null, { updateThemeSettings })(App);
