import React, { useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "../../../vendor/libs/react-datepicker/react-datepicker.scss";
import { getCurrentPartnerId } from "../../../utils/auth/partnerhelpers";
import Loader from "../../../shared/Loader";
import JobSelector from "../jobs/JobSelector";
import UserSelector from "../jobs/UserSelector";
import DisabledFeaturePrompt from "../DisabledFeaturePrompt";

export default function TaskModal(props) {
  const defaultInputRef = useRef(null);
  const { loading, data, error, refetch } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: { id: props.id },
  });

  useEffect(() => {
    if (props.modalOpen) {
      setTimeout(() => {
        if (defaultInputRef.current) {
          defaultInputRef.current.focus();
        }
      }, 200);
    }
  }, [props.id, props.modalOpen]);

  useEffect(() => {
    if (
      props.completedNextClientTaskIds &&
      props.completedNextClientTaskIds.length > 0
    ) {
      refetch();
    }
  }, [props.completedNextClientTaskIds]);

  const [updateTask] = useMutation(UPDATE, {
    refetchQueries: ["getTask", "getTasks", "getNotifications"],
  });

  const [updateJobOnHold] = useMutation(UPDATE_JOB_ON_HOLD);

  const [removeNextClientContact] = useMutation(REMOVE_NEXT_CLIENT_CONTACT, {
    refetchQueries: ["getTask", "getTasks", "getNotifications"],
  });

  const [completeTask] = useMutation(MARK_COMPLETE, {
    refetchQueries: ["getTask", "getTasks", "getNotifications"],
  });

  if (loading)
    return (
      <Modal
        show={props.modalOpen}
        size="lg"
        backdrop="static"
        onHide={() => props.setModalOpen(false)}
      >
        <Modal.Body className="bg-lighter">
          <div className="row">
            <div className="col-xl-12">
              <Loader />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  if (props.claims.subscription_id < 3) {
    return (
      <Modal
        show={props.modalOpen}
        size="lg"
        backdrop="static"
        onHide={() => props.setModalOpen(false)}
      >
        <Modal.Header closeButton style={{ display: "block" }}>
          <Modal.Title as="h5">Add Task </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-lighter">
          <div className="row">
            <div className="col-xl-12">
              <DisabledFeaturePrompt
                descriptionText="Our 'Complete' software plan includes automated and manually generated tasks. Create tasks for yourself and your team, and manage automated tasks that are generated from your jobs."
                claims={props.claims}
                topPosition="10px"
              />
              <div
                style={{
                  opacity: 0.4,
                  pointerEvents: "none",
                  minHeight: "300px",
                }}
              ></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  if (error) return <span>{JSON.stringify(error)}</span>;

  const updateData = async (values) => {
    var calendar_event = {
      partner_id: getCurrentPartnerId(),
      job_id: values.job_id,
      title: values.title,
      description: values.description,
      assigned_to: values.assigned_to,
      start_date: values.start_date,
      end_date: values.end_date,
      is_all_day: values.is_all_day,
      is_task: values.is_task,
      is_next_client_contact: values.is_next_client_contact,
    };

    if (values.notify_user_ids && values.notify_user_ids.length > 0) {
      calendar_event.calendar_event_users = {
        data: values.notify_user_ids.map((n) => ({
          user_id: n,
        })),
      };
    }

    if (values.id != 0) {
      calendar_event.id = values.id;
    }

    let result = await updateTask({
      variables: {
        calendar_event: calendar_event,
      },
    });

    if (props.isOnHoldTask) {
      let onHoldResult = await updateJobOnHold({
        variables: {
          id: props.jobId,
          prior_status_id: props.job_status_id,
        },
      });
    }

    toast("Changes Saved!", {
      type: toast.TYPE.PRIMARY,
      autoClose: 2000,
    });

    if (
      props.selectedNextClientTaskIds &&
      props.selectedNextClientTaskIds.length > 0
    ) {
      let completedTasks = [
        ...props.completedNextClientTaskIds,
        props.selectedNextClientTaskIds.filter(
          (f) => f.job_id == values.job_id
        )[0],
      ];

      if (
        props.selectedNextClientTaskIds.length !=
        props.completedNextClientTaskIds.length
      ) {
        let nextTask = props.selectedNextClientTaskIds.filter(
          (f) => completedTasks.map((m) => m.id).indexOf(f.id) == -1
        );

        if (nextTask.length == 0) {
          props.setModalOpen(false);
          return;
        }
        props.setModalOpen(false);
        props.setJobId(nextTask[0].job_id);
        props.setIsNextClientContact(true);
        props.setCompletedNextClientTaskIds(completedTasks);
        props.setModalNextContactOpen(nextTask[0].id);
      }

      return;
    }

    props.setModalOpen(false);
  };

  var calendar_event = data.calendar_event[0];

  if (!calendar_event) {
    calendar_event = {
      job_id: props.jobId,
      title: "",
      description: "",
      notify_user_ids: [],
      assigned_to: props.claims.user_id,
      start_date: props.is_next_client_contact
        ? moment().local().add(1, "days").startOf("day")
        : moment().local().startOf("day"),
      end_date: props.is_next_client_contact
        ? moment().local().add(1, "days").startOf("day")
        : moment().local().startOf("day"),
      is_all_day: true,
      is_task: !props.isNotification,
      is_next_client_contact: props.is_next_client_contact ? true : false,
    };
  }

  return (
    <Modal
      show={props.modalOpen}
      size="lg"
      backdrop="static"
      onHide={() => props.setModalOpen(false)}
    >
      <Formik
        enableReinitialize
        initialValues={calendar_event}
        onSubmit={(values, { setSubmitting }) => {
          updateData(values);
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .strict(false)
            .trim()
            .required("Title is required"),
          start_date: Yup.string()
            .nullable()
            .strict(false)
            .trim()
            .when("is_task", {
              is: true,
              then: Yup.string().required("Required"),
            }),
          end_date: Yup.string()
            .nullable()
            .strict(false)
            .trim()
            .when("is_task", {
              is: true,
              then: Yup.string().required("Required"),
            }),
        })}
        render={({
          form,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {props.claims.subscription_id < 3 && (
              <DisabledFeaturePrompt
                descriptionText={
                  values.is_next_client_contact
                    ? `'Set it and forget it' with the Next Client Contact. This button allows you to plan the next contact that your client needs. Make a note, pick a day, and a task will appear on your task list that day to remind you. Upgrade to the 'Complete' Plan to start using this feature.`
                    : "Our 'Complete' software plan includes automated and manually generated tasks. Create tasks for yourself and your team, and manage automated tasks that are generated from your jobs."
                }
                claims={props.claims}
              />
            )}

            <Modal.Header closeButton style={{ display: "block" }}>
              <Modal.Title as="h5">
                {props.id != 0 ? (
                  <span>Edit Task</span>
                ) : (
                  <span>
                    Add {props.isNotification ? "Notification" : "Task"}
                  </span>
                )}
              </Modal.Title>
            </Modal.Header>
            {props.selectedNextClientTaskIds &&
              props.selectedNextClientTaskIds.length > 0 && (
                <Row
                  style={
                    props.claims.subscription_id < 3
                      ? { opacity: 0.25, pointerEvents: "none" }
                      : {}
                  }
                >
                  <Col>
                    <Card>
                      <Card.Body>
                        <div className="font-weight-semibold mb-3">
                          Next Client Contact Progress (
                          {props.completedNextClientTaskIds.length + 1} of{" "}
                          {props.selectedNextClientTaskIds.length})
                        </div>

                        <ProgressBar
                          variant="danger"
                          now={
                            3 +
                            parseInt(
                              ((props.completedNextClientTaskIds.length + 1) /
                                props.selectedNextClientTaskIds.length) *
                                100
                            )
                          }
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            <Modal.Body
              style={
                props.claims.subscription_id < 3
                  ? { opacity: 0.25, pointerEvents: "none" }
                  : {}
              }
            >
              <Row>
                <Col sm={12} className="p-2">
                  {props.isOnHoldTask && (
                    <Form.Group as={Row}>
                      <Form.Label column sm={2} className="text-sm-right">
                        &nbsp;
                      </Form.Label>
                      <Col sm={9}>
                        <p>
                          Adding this next client contact task will update the
                          status to <b>On Hold</b> and automatically revert to
                          the previous status when the date of the next client
                          contact arrives.
                        </p>
                      </Col>
                    </Form.Group>
                  )}
                  {values.is_next_client_contact &&
                    !props.selectedNextClientTaskIds && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={2} className="text-sm-right">
                          &nbsp;
                        </Form.Label>
                        <Col sm={9}>
                          <p>
                            This task's end date will set the Next Client
                            Contact Date.{" "}
                            {props.id != 0 && (
                              <span>
                                Click the button below if you want to create a
                                NEW next client contact task and make this task
                                still remain due.
                              </span>
                            )}
                          </p>
                          {props.id != 0 && (
                            <p>
                              <Button
                                className="btn-sm"
                                type="button"
                                variant="action"
                                onClick={async () => {
                                  // update this item to be no longer next client contact and open new task window
                                  let result = await removeNextClientContact({
                                    variables: {
                                      id: props.id,
                                    },
                                  });

                                  if (
                                    props.jobId != null &&
                                    values.is_next_client_contact == true &&
                                    result.data.update_calendar_event
                                      .returning[0].job.job_summary
                                      .next_client_contact_date == null
                                  ) {
                                    props.setModalOpen(false);
                                    if (props.setModalNextContactOpen)
                                      props.setModalNextContactOpen(0);
                                  } else {
                                    props.setModalOpen(false);
                                  }
                                }}
                              >
                                Create New <b>Next Client Contact</b> Task
                              </Button>
                            </p>
                          )}
                        </Col>
                      </Form.Group>
                    )}
                  <Form.Group as={Row}>
                    <Form.Label column sm={2} className="text-sm-right">
                      Job
                    </Form.Label>
                    <Col sm={9}>
                      <JobSelector
                        name="job_id"
                        value={values.job_id}
                        handleChange={(name, e) => setFieldValue("job_id", e)}
                        handleBlur={handleBlur}
                        errors={errors.job_id}
                        touched={touched.job_id}
                        isMulti={false}
                        isClearable={true}
                        placeholder="None"
                        disabled={values.is_next_client_contact}
                      />
                      {errors.job_id ? (
                        <div className="text-danger">{errors.job_id}</div>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2} className="text-sm-right">
                      Task
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        ref={defaultInputRef}
                        type="text"
                        name="title"
                        placeholder="Enter a title"
                        value={values.title}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        className={
                          touched.title && errors.title ? "error" : null
                        }
                      />
                      {touched.title && errors.title ? (
                        <div className="text-danger">{errors.title}</div>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2} className="text-sm-right">
                      Additional Notes
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="description"
                        placeholder="Enter additional notes for this task"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          touched.description && errors.description
                            ? "error"
                            : null
                        }
                      />
                      {errors.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </Col>
                  </Form.Group>
                  {!values.id && (
                    <>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2} className="text-sm-right">
                          Notify
                        </Form.Label>
                        <Col sm={9}>
                          <UserSelector
                            roleNames={[
                              "Admin",
                              "Head Project Manager",
                              "Project Manager",
                              "Estimator",
                              "Accounting",
                              "Sales",
                              "Field Service Tech",
                              "Owner/Operator",
                            ]}
                            className="float-right mb-1 mr-1"
                            id="notify_user_ids"
                            name="notify_user_ids"
                            value={values.notify_user_ids}
                            onChange={(e) => {
                              console.log(e);
                              setFieldValue("notify_user_ids", e);
                            }}
                            onBlur={handleBlur}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Choose users..."
                          />
                          {errors.description ? (
                            <div className="text-danger">
                              {errors.description}
                            </div>
                          ) : null}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={2} className="text-sm-right">
                          Create Task?
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Check
                            id="is_task"
                            name="is_task"
                            as={Field}
                            className="float-left mt-2 small"
                            custom
                            type="checkbox"
                            checked={values.is_task}
                            onChange={handleChange}
                            label=""
                            disabled={values.is_next_client_contact}
                          />
                        </Col>
                      </Form.Group>
                    </>
                  )}
                  {values.is_task && (
                    <>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2} className="text-sm-right">
                          Assign To
                        </Form.Label>
                        <Col sm={9}>
                          <UserSelector
                            roleNames={[
                              "Admin",
                              "Head Project Manager",
                              "Project Manager",
                              "Estimator",
                              "Accounting",
                              "Sales",
                              "Field Service Tech",
                              "Owner/Operator",
                            ]}
                            className="float-right mb-1 mr-1"
                            id="assigned_to"
                            name="assigned_to"
                            value={values.assigned_to}
                            onChange={(e) => {
                              setFieldValue("assigned_to", e);
                            }}
                            onBlur={handleBlur}
                            isClearable={false}
                            isMulti={false}
                            placeholder="Choose user..."
                          />
                          {errors.assigned_to ? (
                            <div className="text-danger">
                              {errors.assigned_to}
                            </div>
                          ) : null}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2} className="text-sm-right">
                          Start / End
                        </Form.Label>
                        <Col sm={3}>
                          <Field name="start_date">
                            {({ field }) => (
                              <>
                                <DatePicker
                                  className="form-control"
                                  calendarClassName={
                                    values.is_all_day
                                      ? ""
                                      : "react-datepicker--with-time"
                                  }
                                  todayButton={"Today"}
                                  selected={
                                    field.value == null
                                      ? null
                                      : new Date(field.value)
                                  }
                                  placeholderText="Select start"
                                  onChange={(value) => {
                                    if (
                                      moment(value).diff(
                                        moment(values.end_date)
                                      ) >= 0
                                    ) {
                                      setFieldValue("end_date", value);
                                    }
                                    setFieldValue("start_date", value);
                                  }}
                                  showTimeSelect={!values.is_all_day}
                                  timeFormat="h:mm aa"
                                  timeIntervals={15}
                                  dateFormat={
                                    values.is_all_day
                                      ? "MM/dd/yyyy"
                                      : "MM/dd/yyyy h:mm aa"
                                  }
                                  timeCaption="Time"
                                  popperPlacement="auto-start"
                                />
                              </>
                            )}
                          </Field>

                          {errors.start_date ? (
                            <div className="text-danger">
                              {errors.start_date}
                            </div>
                          ) : null}
                        </Col>
                        <Col sm={1} className="text-center pt-1">
                          {" "}
                          to{" "}
                        </Col>
                        <Col sm={3}>
                          <Field name="end_date">
                            {({ field }) => (
                              <>
                                <DatePicker
                                  className="form-control"
                                  minDate={
                                    values.is_next_client_contact &&
                                    props.id === 0
                                      ? moment().add(1, "days").toDate()
                                      : moment().add(-100, "years").toDate()
                                  }
                                  calendarClassName={
                                    values.is_all_day
                                      ? ""
                                      : "react-datepicker--with-time"
                                  }
                                  todayButton={"Today"}
                                  selected={
                                    field.value == null
                                      ? null
                                      : new Date(field.value)
                                  }
                                  placeholderText="Select end"
                                  onChange={(value) => {
                                    if (
                                      moment(value).diff(
                                        moment(values.start_date)
                                      ) < 0
                                    ) {
                                      setFieldValue("start_date", value);
                                    }
                                    setFieldValue("end_date", value);
                                  }}
                                  showTimeSelect={!values.is_all_day}
                                  timeFormat="h:mm aa"
                                  timeIntervals={15}
                                  dateFormat={
                                    values.is_all_day
                                      ? "MM/dd/yyyy"
                                      : "MM/dd/yyyy h:mm aa"
                                  }
                                  timeCaption="Time"
                                  popperPlacement="auto-start"
                                />
                              </>
                            )}
                          </Field>

                          {errors.end_date ? (
                            <div className="text-danger">{errors.end_date}</div>
                          ) : null}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2} className="text-sm-right">
                          All Day?
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Check
                            id="is_all_day"
                            name="is_all_day"
                            as={Field}
                            className="float-left mt-2 small"
                            custom
                            type="checkbox"
                            checked={values.is_all_day}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue(
                                  "start_date",
                                  moment(
                                    moment(values.start_date)
                                      .local()
                                      .format("MM/DD/YYYY")
                                  ).add(8, "hours")
                                );
                                setFieldValue(
                                  "end_date",
                                  moment(
                                    moment(values.end_date)
                                      .local()
                                      .format("MM/DD/YYYY")
                                  ).add(8, "hours")
                                );
                              }
                              handleChange(e);
                            }}
                            label=""
                          />
                        </Col>
                      </Form.Group>
                    </>
                  )}
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <div className="mr-auto float-left">
                {props.id != 0 && (
                  <Button
                    type="button"
                    variant="action"
                    onClick={async () => {
                      let result = await completeTask({
                        variables: {
                          id: props.id,
                        },
                      });

                      if (
                        values.is_next_client_contact == true &&
                        result.data.update_calendar_event.returning[0].job
                          .job_summary.next_client_contact_date == null
                      ) {
                        props.setJobId(values.job_id);
                        props.setIsNextClientContact(true);
                        props.setModalNextContactOpen(0);
                      } else {
                        toast("Task completed!", {
                          type: toast.TYPE.PRIMARY,
                          autoClose: 2000,
                        });
                        props.setModalOpen(false);
                      }
                    }}
                  >
                    Mark task complete
                  </Button>
                )}
              </div>
              <Button
                variant="default"
                onClick={() => props.setModalOpen(false)}
              >
                Close
              </Button>
              {(!props.selectedNextClientTaskIds ||
                props.selectedNextClientTaskIds.length === 0 ||
                props.id === 0) && (
                <Button
                  type="submit"
                  variant="primary"
                  disabled={props.claims.subscription_id < 3}
                >
                  {props.id != 0 ? <span>Save</span> : <span>Create</span>}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
}

const QUERY = gql`
  query getTask($id: Int!) {
    calendar_event(where: { id: { _eq: $id } }) {
      id
      job_id
      title
      description
      assigned_to
      start_date
      end_date
      is_all_day
      is_task
      is_next_client_contact
    }
  }
`;

const MARK_COMPLETE = gql`
  mutation updateTask($id: Int!) {
    update_calendar_event(
      _set: { is_completed: true }
      where: { id: { _eq: $id } }
    ) {
      returning {
        job {
          job_summary {
            next_client_contact_date
          }
        }
      }
    }
  }
`;

const REMOVE_NEXT_CLIENT_CONTACT = gql`
  mutation updateTask($id: Int!) {
    update_calendar_event(
      _set: { is_next_client_contact: false }
      where: { id: { _eq: $id } }
    ) {
      returning {
        job {
          job_summary {
            next_client_contact_date
          }
        }
      }
    }
  }
`;

const UPDATE = gql`
  mutation updateTask($calendar_event: [calendar_event_insert_input!]!) {
    insert_calendar_event(
      objects: $calendar_event
      on_conflict: {
        constraint: calendar_event_pkey
        update_columns: [
          partner_id
          job_id
          title
          description
          assigned_to
          start_date
          end_date
          is_all_day
          is_task
          is_next_client_contact
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_JOB_ON_HOLD = gql`
  mutation updateJobOnHold($id: Int!, $prior_status_id: Int!) {
    update_job(
      _set: { status_id: 100, prior_status_id: $prior_status_id }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
      }
    }
  }
`;
