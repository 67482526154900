import React, { useState, useEffect } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import gql from "graphql-tag";
import { Button, Nav } from "react-bootstrap";
import { useQuery } from "@apollo/react-hooks";
import {
  getCurrentPartnerId,
  setCurrentPartnerId,
} from "../../../utils/auth/partnerhelpers";
import ConnectToQuickBooks from "../../partners/ConnectToQuickBooks";
import QuickbooksDefaultItemModal from "../../partners/QuickbooksDefaultItemModal";

export default function PartnerNavSelector(props) {
  let history = useHistory();
  const [modalDefaultItemOpen, setModalDefaultItemOpen] = useState(false);
  const [redirectLink, setRedirectLink] = useState(null);
  const [currentPartner, setCurrentPartner] = useState(null);
  const [partnerId, setPartnerId] = useState(getCurrentPartnerId());
  const { loading, data, error, refetch } = useQuery(QUERY, {
    variables: { user_id: props?.claims?.user_id },
    skip: !props?.claims?.user_id,
  });
  let { pid } = useParams();

  const partnerChangeListener = (e) => {
    setPartnerId(getCurrentPartnerId());
  };

  useEffect(() => {
    refetch();
  }, [props.claims]);

  useEffect(() => {
    window.addEventListener("updatePartner", partnerChangeListener);
    return () =>
      window.removeEventListener("updatePartner", partnerChangeListener);
  }, []);

  useEffect(() => {
    if (data?.user?.primary_partner) {
      let currentPartner = data?.user?.primary_partner;
      setCurrentPartner(currentPartner);
      if (
        currentPartner &&
        currentPartner.qb_item_id == null &&
        currentPartner.is_qb_activated
      ) {
        setModalDefaultItemOpen(true);
      }

      setPartnerId(data?.user?.primary_partner.id);
      setCurrentPartnerId(data?.user?.primary_partner.id);
    } 
  }, [data]);

  useEffect(() => {
    if (redirectLink != null) {
      setRedirectLink(null);
    }
  }, [redirectLink]);

  if (loading || error) return <div></div>;

  if (redirectLink != null) {
    return <Redirect to={`/dashboard/${redirectLink?.partnerId}`} />;
  }

  return (
    <>
      {/* {currentPartner != null && currentPartner?.is_test_partner == true && (
        <Nav.Link className="demo-navbar-notifications mr-lg-2">
          <div style={{ position: "relative" }}>
            <a className="btn btn-sm btn-danger" href="#">
              -- <i className="fa fa-exclamation-triangle"></i> TEST MODE --
            </a>
          </div>
        </Nav.Link>
      )} */}
      {currentPartner != null &&
        currentPartner.is_qb_activated &&
        currentPartner.qb_item_id == null && (
          <>
            <Nav.Link className="demo-navbar-notifications mr-lg-2">
              <div style={{ position: "relative" }}>
                <a
                  className="btn btn-sm btn-danger"
                  href="#"
                  onClick={() => {
                    setModalDefaultItemOpen(true);
                  }}
                >
                  <i className="fa fa-exclamation-triangle"></i> YOU ARE MISSING
                  KEY QUICKBOOKS SETTINGS
                </a>
              </div>
            </Nav.Link>
            {modalDefaultItemOpen && (
              <QuickbooksDefaultItemModal
                modalOpen={modalDefaultItemOpen}
                setModalOpen={setModalDefaultItemOpen}
              />
            )}
          </>
        )}
      {/* {currentPartner != null &&
        currentPartner?.is_test_partner != true &&
        !currentPartner?.is_qb_activated &&
        (props.claims.default_role === "superadmin" ||
          props.claims.default_role == "owneroperator" ||
          props.claims.default_role === "teamadmin") && (
          <Nav.Link className="demo-navbar-notifications mr-lg-2 p-0">
            <div style={{ position: "relative" }}>
              <ConnectToQuickBooks
                is_test_partner={currentPartner?.is_test_partner}
              />
            </div>
          </Nav.Link>
        )} */}
      {/* Removed Plaid in favor of Payrix */}
      {/* {currentPartner != null &&
        currentPartner.stripe_customer_id == null &&
        (props.claims.default_role === "superadmin" ||
          props.claims.default_role == "owneroperator" ||
          props.claims.default_role === "teamadmin") && (
          <Nav.Link className="demo-navbar-notifications mr-lg-2">
            <div style={{ position: "relative" }}>
              <PartnerPlaidActivation partner_id={partnerId} />
            </div>
          </Nav.Link>
        )} */}
      {/* Removed ability to switch partners */}
      {/* <Nav.Link className="demo-navbar-notifications mr-lg-2">
        <div style={{ position: "relative" }}>
          <Button
            variant="default"
            size="sm"
            className="rounded-pill"
            onClick={() => setPartnerSelectOpen(true)}
          >
            <i className="fas fa-users"></i>{" "}
            <strong>&nbsp;{partnerName}</strong>{" "}
            <i className="fas fa-angle-down"></i>
          </Button>
          {partnerSelectOpen ? (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 4,
                boxShadow: `0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)`,
                marginTop: 8,
                position: "absolute",
                zIndex: 2,
                fontSize: "13px",
              }}
            >
              <Select
                id="partnerId"
                menuIsOpen
                autoFocus
                styles={selectStyles}
                placeholder="Search..."
                options={options}
                onChange={handleChange}
                defaultValue={options.filter(
                  (option) => option.value == partnerId
                )}
                isMulti={false}
                isClearable={false}
              />
            </div>
          ) : null}
          {partnerSelectOpen ? (
            <div
              style={{
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
                position: "fixed",
                zIndex: 1,
              }}
              onClick={() => setPartnerSelectOpen(false)}
            />
          ) : null}
        </div>
      </Nav.Link> */}
    </>
  );
}

export const QUERY = gql`
  query GetPrimaryPartner($user_id: Int!) {
    user: user_by_pk(id: $user_id) {    	  
      primary_partner {
        id
        name
        plaid_account_id
        stripe_customer_id
        is_qb_activated
        is_test_partner
        qb_item_id
        subscription_id
      }
    }
  }
`;
