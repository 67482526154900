import React, { useEffect } from "react";
const axios = require("axios");

export default function ConnectToQuickBooks(props) {
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_SERVICES_URL + "/api/QuickbooksGrant", {
        is_test_partner: props.is_test_partner,
      })
      .then((response) => {
        init(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function init(connectUrl) {
    let script1 = document.createElement("script");
    script1.src =
      "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js";
    script1.type = "text/javascript";
    script1.onload = () => {
      let script2 = document.createElement("script");
      script2.src =
        "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js";
      script2.type = "text/javascript";
      script2.onload = () => {
        let script3 = document.createElement("script");
        script3.innerHTML = `
          intuit.ipp.anywhere.setup({
            grantUrl: '${connectUrl}'
          });
        `;
        script3.type = "text/javascript";

        document.getElementsByTagName("head")[0].appendChild(script3);
      };
      document.getElementsByTagName("head")[0].appendChild(script2);
    };
    document.getElementsByTagName("head")[0].appendChild(script1);
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html:
            "<ipp:connectToIntuit>Loading, please wait...</ipp:connectToIntuit>",
        }}
      ></div>
    </>
  );
}
