import { actionTypes } from './actionTypes';
const DEFAULT_PAGE_SIZE = 15;
const initialState = {
    messages: [],
    selectedMessages: [],
    messageCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: 0,
    sortBy: {
        id: 'date',
        desc: true
    }
};

function sortByAtt(sortBy, collection) {
    let collectionAux = [...collection];
    let stringFields = ['subject', 'name', 'from'];
    let dateField = ['date'];
    if (collectionAux.length > 0) {
        if (sortBy.desc) {
            collectionAux.sort((a,b) => {
                if (stringFields.lastIndexOf(sortBy.id) !== -1) {
                    return a[sortBy.id].localeCompare(b[sortBy.id])
                }

                return a[sortBy.id] - b[sortBy.id];
            });
        } else {
            collectionAux.sort((a,b) => {

                if (stringFields.lastIndexOf(sortBy.id) !== -1) {
                    return b[sortBy.id].localeCompare(a[sortBy.id]);
                }

                return  b[sortBy.id] - a[sortBy.id]
            });
        }
    }

    return collectionAux;
}

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.messageCheck:
            return {
                ...state,
                selectedMessages: [...state.selectedMessages, action.payload]
            };
        case actionTypes.messageUncheck:
            return {
                ...state,
                selectedMessages: state.selectedMessages.filter((f) => {
                    return f.id !== action.payload.id;
                })
            };
        case actionTypes.messageClearSelected:
            return {
                ...state,
                selectedMessages: [],
            };
        case actionTypes.messageRetrieve:
            return {
                ...state,
                messages: action.payload,
            };
        case actionTypes.messageCount:
            return {
                ...state,
                messageCount: action.payload,
            };
        case actionTypes.pageSizeChange:
            return {
                ...state,
                pageSize: action.payload
            };
        case actionTypes.pageNumberChange:
            return {
                ...state,
                pageNumber: action.payload
            }
        case actionTypes.currentTab:
            return {
                ...state,
                tab: action.payload
            }

        case actionTypes.sortBy:
            return {
                ...state,
                sortBy: action.payload
            }
    }

    return state;
}