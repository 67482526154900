import { useState, useEffect } from 'react';
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export function useUserData(userID) { 
   const { loading, data, error } = useQuery(GETUSER, {
    fetchPolicy: "no-cache",
    variables: { id: userID },
    });

  return {data,loading,error};
}

const GETUSER = gql`
  query GetUserData($id: Int!) {
    user(where:{id:{_eq:$id}})
    {
      id
      authorize_approve_vendor_payments
      allow_user_pay_vendor_early
      allow_admin_all_user_permissions
      user_roles{
        role_id
      }
    }
  }
`;