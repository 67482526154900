import * as actionType from './actions'; 
export const initialState ={
    selectedBills:[],
    errors:false,
    loading: false
}

export default function billReducer (state=initialState,action)
{
    switch(action.type){
        case actionType.CHECK_BILL:
            return {
                ...state,
                selectedBills: [...state.selectedBills, action.payload]
            };
        case actionType.UN_CHECK_BILL:
            return {
                ...state,
                selectedBills: state.selectedBills.filter((f) => {
                    return f.bill_id !== action.payload;
                })
            };   
        case actionType.UN_CHECK_ALL_BILL:
            return {
                ...state,
                selectedBills: state.selectedBills.filter((f) => {
                    return f.vendor_id !== action.payload;
                })
            };         
        case actionType.CLEAR_SELECTED:
            return {
                ...state,
                selectedBills: [],
            };
        case actionType.GET_BILLS:
            return {
                ...state,
                selectedBills: action.payload,
            };
        default: 
            return state;
    }
}