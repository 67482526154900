import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import UpgradeSubscriptionModal from "../authentication/UpgradeSubscriptionModal";

export default function DisabledFeaturePrompt(props) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          left: 0,
          right: 0,
          bottom: 0,
          top: props.topPosition ?? "50px",
          margin: "auto",
          zIndex: 15,
        }}
      >
        <div
          style={{
            position: "absolute",
            padding: "20px",
            backgroundColor: "white",
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
            borderRadius: "5px",
            left: "50%",
            transform: "translate(-50%, 30%)",
            textAlign: "center",
            zIndex: 200,
            maxWidth: "500px",
          }}
        >
          <p>{props.descriptionText}</p>
          <Button
            variant="success"
            onClick={() => {
              if (
                props.claims &&
                props.claims.default_role == "owneroperator"
              ) {
                setModalOpen(true);
              } else {
                window.alert(
                  "You must be an Owner to upgrade your ProStruct360 subscription."
                );
              }
            }}
          >
            Click here to upgrade
          </Button>
        </div>
      </div>
      {props.claims &&
        props.claims.default_role == "owneroperator" &&
        modalOpen && (
          <UpgradeSubscriptionModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            claims={props.claims}
          />
        )}
    </>
  );
}
