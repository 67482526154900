import React, { useState, useEffect } from "react";
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";
import layoutHelpers from "./helpers";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import gql from "graphql-tag";
import { useImperativeQuery } from "../../utils/gqlutils";
import { subscriptions } from "../../utils/subscriptionsPresets";

export default function Layout2(props) {
  const getSubscriptionLevel = useImperativeQuery(SUBSCRIPTION_QUERY);
  const [userId, setUserId] = useState(props?.claims?.user_id);

  useEffect(() => {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);

    return function cleanup() {
      layoutHelpers.destroy();
    };
  }, []);

  useEffect(() => {
    // set permissions on claim object
    if (props?.claims?.user_id && props?.claims?.user_id !== userId) {
      setUserId(props?.claims?.user_id);
      setSubscriptionLevel();
    }
  }, [props.claims]);

  async function setSubscriptionLevel() {
    if (props.claims?.user_id) {
      let result = await getSubscriptionLevel({
        id: props.claims.user_id,
      });

      if (!result.data?.user?.is_active) {
        window.location.href = "/login?message=inactive";
        return null;
      }

      let claims = JSON.parse(JSON.stringify(props.claims));
      claims.subscription_id = result.data?.user?.primary_partner
        ?.subscription_id
        ? parseInt(result.data?.user?.primary_partner?.subscription_id)
        : 1;
      claims.primary_partner_id = result.data?.user?.primary_partner?.id
        ? parseInt(result.data?.user?.primary_partner?.id)
        : 0;
      claims.logo_url =
        result.data?.user?.primary_partner?.logo_url ??
        `${process.env.PUBLIC_URL}/img/logo_prostruct360.png`;
      claims.brand_hex_color =
        result.data?.user?.primary_partner?.brand_hex_color ?? "#0a314a";
      props.setClaims(claims);

      if (window.StonlyWidget) {
        window.StonlyWidget("identify", claims.user_id, {
          subscription_type: subscriptions.filter(
            (f) => f.id == claims.subscription_id
          )?.[0]?.name,
          permission_level: claims.default_role,
          company_name: result.data?.user?.primary_partner?.name,
        });
      }
    }
  }

  function closeSidenav(e) {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  }

  if (!props.user) {
    if (props.claims?.default_role == "" && props.claims?.initial == false) {
      window.location.href = "/login";
      return null;
    }
  }

  if (props.claims.initial == true) {
    return null;
  }

  return (
    <div className="layout-wrapper layout-2">
      <div className="layout-inner">
        <LayoutSidenav {...props} />

        <div className="layout-container">
          <LayoutNavbar {...props} />

          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              <ErrorBoundary>{props.children}</ErrorBoundary>
            </div>

            <LayoutFooter {...props} />
          </div>
        </div>
      </div>
      <div className="layout-overlay" onClick={closeSidenav}></div>
    </div>
  );
}

const SUBSCRIPTION_QUERY = gql`
  query GetSubscription($id: Int!) {
    user: user_by_pk(id: $id) {
      id
      is_active
      primary_partner {
        id
        name
        logo_url
        brand_hex_color
        subscription_id
      }
    }
  }
`;
