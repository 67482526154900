import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import AsyncSelect from "react-select/async";
import { useImperativeQuery } from "../../../utils/gqlutils";
import { getCurrentPartnerId } from "../../../utils/auth/partnerhelpers";
import { useQuery } from "@apollo/react-hooks";

export default function JobSelector(props) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const { loading, data, error } = useQuery(QUERY_TEMPLATES, {
    fetchPolicy: "no-cache",
    variables: {
      partner_ids: props.partnerIds
        ? props.partnerIds
        : [getCurrentPartnerId()],
    },
  });

  const callQuery = useImperativeQuery(QUERY);
  const callQuerySingle = useImperativeQuery(QUERY_SINGLE);

  useEffect(() => {
    if (data && data.job && props.showTemplates) {
      let options = data.job.map(function (e) {
        return {
          value: e.id,
          label: e.name,
        };
      });
      setDefaultOptions(options);
    }
  }, [data]);

  useEffect(() => {
    if (!props.value) {
      setSelectedValue([]);
    } else {
      setInitialValue();
    }
  }, [props.value]);

  async function setInitialValue() {
    const { data } = await callQuerySingle({
      partner_ids: props.partnerIds
        ? props.partnerIds
        : [getCurrentPartnerId()],
      ids: props.value,
    });
    let options = data.job.map(function (e) {
      return {
        value: e.id,
        label: e.name,
      };
    });
    setSelectedValue(options);
  }

  const getOptions = async (input, callback) => {
    if (!input && props.value && props.value.filter((x) => x).length > 0) {
      const { data, error } = await callQuerySingle({
        partner_ids: props.partnerIds
          ? props.partnerIds
          : [getCurrentPartnerId()],
        ids: props.value,
      });
      let options = data.job.map(function (e) {
        return {
          value: e.id,
          label: e.name,
        };
      });
      callback(options);
      setSelectedValue(options);
    } else {
      if (input && input.trim().length < 0) {
        let options = [];
        callback(options);
      } else {
        const { data, error } = await callQuery({
          partner_ids: props.partnerIds
            ? props.partnerIds
            : [getCurrentPartnerId()],
          q: "%" + input + "%",
          showTemplates: !props.showTemplates
            ? { status_id: { _neq: 200 } }
            : {},
        });

        if (data && data.job) {
          let options = data.job.map(function (e) {
            return {
              value: e.id,
              label: e.name,
            };
          });
          callback(options);
        }
      }
    }
  };

  function handleChange(e) {
    setSelectedValue(e);

    props.handleChange(
      props.name,
      e == null ? [] : !props.isMulti ? e.value : e.map((n) => n.value)
    );
  }

  function handleBlur() {
    props.handleBlur(props.name, true);
  }

  return (
    <>
      <AsyncSelect
        id={props.name}
        value={selectedValue}
        defaultOptions={defaultOptions}
        loadOptions={getOptions}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={props.placeholder ? props.placeholder : "Choose a job..."}
        noOptionsMessage={(value) =>
          value ? "Start typing to search for a job..." : ""
        }
        isMulti={props.isMulti}
        closeMenuOnSelect={!props.isMulti}
        className="select"
        isDisabled={props.disabled}
        isClearable={props.isClearable}
      />
    </>
  );
}

const QUERY = gql`
  query getJobDropdown(
    $partner_ids: [Int!]
    $q: String
    $showTemplates: job_bool_exp
  ) {
    job(
      where: {
        _and: [
          { partner_id: { _in: $partner_ids } }
          { is_active: { _eq: true } }
          { status_id: { _neq: 90 } }
          $showTemplates
          { name: { _ilike: $q } }
        ]
      }
    ) {
      id
      name
    }
  }
`;

const QUERY_TEMPLATES = gql`
  query getTemplates($partner_ids: [Int!]) {
    job(
      where: {
        _and: [
          { partner_id: { _in: $partner_ids } }
          { is_active: { _eq: true } }
          { status_id: { _eq: 200 } }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;

const QUERY_SINGLE = gql`
  query getJobDropdownSingle($partner_ids: [Int!], $ids: [Int!]) {
    job(
      where: {
        _and: [{ partner_id: { _in: $partner_ids } }, { id: { _in: $ids } }]
      }
    ) {
      id
      name
    }
  }
`;
