import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Sidenav from "../../vendor/libs/sidenav";
import ScrollArea from "react-scrollbar";
import layoutHelpers from "./helpers";
import useLocalStorage from "../../utils/localStorageUtils";
import { hexToHSL } from "../../utils/colorutils";
import {useUserData} from "../../utils/auth/useUserData";


export default function LayoutSidenav(props) {
  const history = useHistory();
  const sideNavRef = useRef(null);
  const [leftNavExpanded, setLeftNavExpanded] = useLocalStorage(
    "left_nav_expanded",
    true
  );
  const { loading, data, error } = useUserData(props.claims.user_id);
  useEffect(() => {
    if (leftNavExpanded && layoutHelpers.isCollapsed()) {
      layoutHelpers.toggleCollapsed();
    }
    if (!leftNavExpanded && !layoutHelpers.isCollapsed()) {
      layoutHelpers.toggleCollapsed();
    }
    //console.log("sideNavRef", sideNavRef?.current?.props?.className);
  }, [leftNavExpanded]);

  // useEffect(() => {
  //   let hslValues = hexToHSL(props.claims.brand_hex_color ?? "#0a314a");
  //   if (
  //     sideNavRef?.current?.props?.className.indexOf(" bg-sidenav-theme") != -1
  //   ) {
  //     let bg =
  //       hslValues?.l <= 0.5
  //         ? "new-bg-sidenav-theme"
  //         : "new-bg-sidenav-theme-light";
  //   }
  // }, [sideNavRef]);

  function layoutSidenavClasses(brand_hex_color) {
    let hslValues = hexToHSL(brand_hex_color ?? "#0a314a");

    let bg =
      "bg-sidenav-theme " +
      (hslValues?.l <= 0.5
        ? "new-bg-sidenav-theme"
        : "new-bg-sidenav-theme-light");

    if (
      props.orientation === "horizontal" &&
      (bg.indexOf(" sidenav-dark") !== -1 ||
        bg.indexOf(" sidenav-light") !== -1)
    ) {
      bg = bg
        .replace(" sidenav-dark", "")
        .replace(" sidenav-light", "")
        .replace("-darker", "")
        .replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (props.orientation !== "horizontal"
        ? "layout-sidenav"
        : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  }

  function toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
    setLeftNavExpanded(!layoutHelpers.isCollapsed());
  }

  function openSidenav(e) {
    e.preventDefault();
    if (!leftNavExpanded && layoutHelpers.isCollapsed()) {
      layoutHelpers.toggleCollapsed();
      setLeftNavExpanded(!layoutHelpers.isCollapsed());
    }
  }

  function isMenuActive(url) {
    return props.location.pathname.indexOf(url) === 0;
  }

  function isMenuOpen(url) {
    return (
      props.location.pathname.indexOf(url) === 0 &&
      props.orientation !== "horizontal"
    );
  }

  let defaultRole = props.claims.default_role;

  if (
    (defaultRole === "vendor" || defaultRole === "customer") &&
    (window.location.pathname == "/dashboard" ||
      window.location.pathname == "/")
  ) {
    window.location.href = "/jobs";
  }
  return (
    <>
      <Sidenav
        ref={sideNavRef}
        orientation={props.orientation}
        className={layoutSidenavClasses(props.claims.brand_hex_color)}
        style={{ background: props.claims.brand_hex_color }}
      >
        {/* Brand demo (see src/demo.css) */}
        {props.orientation !== "horizontal" && (
          <React.Fragment>
            <div className="app-brand demo">
              <Link
                className="app-brand-text demo sidenav-text font-weight-normal ml-1 mt-1"
                onClick={(e) => {
                  history.push("/dashboard");
                  openSidenav(e);
                }}
              >
                <img
                  src={props.claims.logo_url}
                  alt="ProStruct360"
                  className="app-brand-logo demo"
                />
                <div className="app-brand-mark demo">
                  <a href="#toggle" className="ml-auto hamburger">
                    <i className="ion ion-md-menu align-middle"></i>
                  </a>
                </div>
              </Link>
              <a
                href="#toggle"
                className="layout-sidenav-toggle sidenav-link text-large ml-auto"
                onClick={toggleSidenav}
              >
                <i className="ion ion-md-menu align-middle"></i>
              </a>
            </div>
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}

        <ScrollArea>
          <div
            className={`sidenav-inner ${
              props.orientation !== "horizontal" ? "py-1" : ""
            }`}
          >
            {defaultRole != "vendor" && defaultRole != "customer" && (
              <Sidenav.RouterLink to="/dashboard" icon="ion ion-md-speedometer">
                Dashboard
              </Sidenav.RouterLink>
            )}
            <Sidenav.RouterLink to="/jobs" icon="fas fa-wrench">
              Jobs
            </Sidenav.RouterLink>
            {/* <Sidenav.RouterLink to="/addresses" icon="fas fa-map-marker">
                Addresses
              </Sidenav.RouterLink> */}
            {(defaultRole == "teamadmin" ||
              defaultRole == "owneroperator" ||
              defaultRole === "superadmin") && (
              <>
                <Sidenav.RouterLink to="/clients" icon="fas fa-users">
                  Clients
                </Sidenav.RouterLink>
                <Sidenav.RouterLink
                  to={`/partner/clientpayments`}
                  icon="fa fa-money-check-alt"
                >
                  Client Payments
                </Sidenav.RouterLink>

                <Sidenav.RouterLink
                  to={`/partner/vendors`}
                  icon="fas fa-hard-hat"
                >
                  Vendors
                </Sidenav.RouterLink>
                <Sidenav.RouterLink
                  to={`/partner/vendor-payment`}
                  icon="fa fa-money-bill"
                >
                  Vendor Payments
                </Sidenav.RouterLink>
                       
              </>
            )}
          
             {(defaultRole == "teamadmin" ||
              defaultRole == "owneroperator" ||
              defaultRole === "superadmin"||
              data?.user[0].allow_admin_all_user_permissions ) && (
              <Sidenav.RouterLink
                  to={`/partner/teamusers`}
                  icon="fas fa-user-lock"
                >
                  Team Members
                </Sidenav.RouterLink>    
              )}
              {(defaultRole == "teamadmin" ||
              defaultRole == "owneroperator" ||
              defaultRole === "superadmin") && (
              <>
                  <Sidenav.RouterLink to="/emails" icon="fas fa-envelope">
                  Emails
                </Sidenav.RouterLink>
              </>
            )}

            {defaultRole == "user" && (
              <>
                <Sidenav.RouterLink to="/emails" icon="fas fa-envelope">
                  Emails
                </Sidenav.RouterLink>
                <Sidenav.RouterLink
                  to={`/partner/vendor-payment`}
                  icon="fa fa-money-bill"
                >
                  Vendor Payments
                </Sidenav.RouterLink>
              </>
            )}
            {/* <Sidenav.RouterLink to="/calendar" icon="fas fa-calendar">
                Calendar
              </Sidenav.RouterLink> */}
            {/* <Sidenav.RouterLink to="/messages" icon="ion ion-ios-chatboxes">
                Messages
              </Sidenav.RouterLink> */}

            {defaultRole != "vendor" && defaultRole != "customer" && (
              <Sidenav.Menu
                icon="fas fa-clipboard"
                linkText="Reports"
                active={isMenuActive("/reports")}
                open={isMenuOpen("/reports")}
              >
                <Sidenav.RouterLink to="/reports/profitlossreport">
                  Profit &amp; Loss
                  <br />
                  Super Report
                </Sidenav.RouterLink>
                {(defaultRole == "owneroperator" || defaultRole == "admin") && (
                  <Sidenav.RouterLink to="/reports/qbologs">
                    Quickbooks Communication Logs
                  </Sidenav.RouterLink>
                )}
              </Sidenav.Menu>
            )}

            {defaultRole == "superadmin" && (
              <>
                <Sidenav.Menu
                  icon="fas fa-lock"
                  linkText="Admin"
                  active={isMenuActive("/admin")}
                  open={isMenuOpen("/admin")}
                >
                  <Sidenav.RouterLink to="/jobleads">Leads</Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/users">
                    All Users
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/partners">
                    Partners
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/billing">
                    Billing
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/catalog">
                    Job Item Catalog
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/suppliers">
                    Suppliers
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/trades">
                    Trades
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/inboxes">Emails</Sidenav.RouterLink>
                </Sidenav.Menu>
              </>
            )}

            <Sidenav.Divider className="mb-1" />
            <Sidenav.Header className="small font-weight-semibold">
              SUPPORT
            </Sidenav.Header>
            <a
              className="sidenav-item d-block "
              target="_blank"
              href="https://help.prostruct360.com/"
            >
              <div className="sidenav-link ">
                <i className="sidenav-icon ion ion-ios-book"></i>
                <div>Knowledge Base</div>
              </div>
            </a>

            <Sidenav.RouterLink to="/support" icon="ion ion-md-help-buoy">
              Support Request
            </Sidenav.RouterLink>
          </div>
        </ScrollArea>
      </Sidenav>
    </>
  );
}
